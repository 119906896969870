<template>
  <main v-if="apiData && includedTeasers">
    <WidthWrapper>
      <div class="max-w-780 mx-auto bg-white pt-20 md:pt-40 px-10 pb-40">
        <PageTitle :title="apiData.data.attributes.name" page-type="category" />
        <section class="grid grid-cols-6 gap-x-20 md:gap-y-60">
          <template v-for="(teaser, index) in includedTeasers.slice(0, 3)">
            <TeaserAside3 v-if="teaser" lazy-img="eager" :key="teaser.id + index" class="col-span-6 md:col-span-2" :teaser="teaser" />
          </template>
          <template v-for="(teaser, index) in includedTeasers.slice(3, 6)">
            <TeaserAside3 v-if="teaser" lazy-img="eager" :key="teaser.id + index" class="col-span-6 md:col-span-2" :teaser="teaser" />
          </template>
        </section>
        <AdWrapper class="mb-40 col-span-6" :ad="{ slotId: 'cncpt-dsk_rec1_DF' }" allow-naughty-ad grey-background />
        <section class="grid grid-cols-6 gap-x-20 gap-y-20 my-40">
          <TeaserPerson v-if="latestSHGirl" lazy-img="eager" class="col-span-6 md:col-span-3" :teaser="latestSHGirl" />
          <TeaserPerson v-if="latestSHBoy" lazy-img="eager" class="col-span-6 md:col-span-3" :teaser="latestSHBoy" gender="Manden" />
        </section>
        <div class="lg:w-700 lg:mx-auto lg:px-0 my-40">
          <LazyPersonSearchForm gender="girl" />
        </div>
        <LoadMore :initial-count="6" :api-url="apiData.links.related" :total-count="apiData.data?.attributes?.articleCount" request-initial :ads="false" />
      </div>
    </WidthWrapper>
    <LazyGlobals />
  </main>
</template>

<script setup lang="ts">
const apiData = inject('apiData') as Aller.Category;
const { includedTeasers } = useAllerRelationships(apiData);

const includedPersons = useIncludedPersons(apiData);

const latestSHGirl = includedPersons.find((person: any) => person.id === apiData.data.relationships.latest_persons?.girl.id);
const latestSHBoy = includedPersons.find((person: any) => person.id === apiData.data.relationships.latest_persons?.boy.id);

useHead({ meta: [{ name: 'rating', content: 'adult' }] });
</script>
