export const useIncludedPersons = (apiData: any) => {
  const persons = apiData.data.included?.filter((element: any) => {
    if (!element) {
      return null;
    }
    return element.type === 'person';
  });
  if (!persons) {
    return null;
  }
  return persons;
};